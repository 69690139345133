.fieldset {
    display: flex;
    flex-direction: column;
    // gap: 2rem;
    // margin-bottom: 2.4rem;
    margin-top: -1rem;
    margin-bottom: 1.4rem;

    &>* {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &_gap-16 {
        // gap: 1.6rem;
        margin-top: -0.8rem;
        margin-bottom: 1.6rem;

        &>* {
            margin-top: 0.8rem;
            margin-bottom: 0.8rem;
        }
    }
}

.field {
    width: 100%;

    &__error {
        // margin-top: 0.6rem;
        text-align: left;
        font-size: 1.2rem;
        font-weight: $font-weight-500;
        line-height: 1.4rem;
        letter-spacing: 0.65%;
        color: $color-red;

        .field_center & {
            text-align: center;
        }
    }
}