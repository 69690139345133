.stories-wrap {
    min-height: 14.4rem;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 2rem 2rem 1.6rem 2rem;
}
.stories {
    min-height: 140px;
}
.stories.carousel {
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .story {
        max-width: none;
        width: 12rem;
        height: 14rem;
        border-radius: 1.2rem;
        border: 0.2rem solid $color-primary-blue;
        margin: 0 0.2rem;

        &.seen {
            border: 2px solid $color-gray-6;
            opacity: 0.7;
        }

        &>.item-link {
            position: relative;
            height: 100%;    
            border-radius: 1.2rem;
            border: 0.2rem solid $color-white;

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 0.8rem;
                background: linear-gradient(180deg, rgba(17, 24, 39, 0) 0%, #111827 100%);
                z-index: 2;
            }

            &>.item-preview {
                max-height: none;
                height: 100%;

                img {
                    border-radius: 0.8rem;
                }
            }

            &>.info {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 0.8rem;
                text-align: left;
                white-space: normal;
                font-size: 1.2rem;
                line-height: 1.2rem;
                font-weight: $font-weight-600;
                color: $color-white;
                z-index: 3;
                letter-spacing: -0.005em;

                .name {
                    font-weight: $font-weight-600;    
                }
            }
        }
    }
}
#zuck-modal-content {
    .story-viewer {
        .head {
            .left {
                .item-preview {
                    display: none;
                }

                .info {
                    display: none;
                }
                
                &>.back {
                    display: none!important;
                }
            }
            .right {
                .time {
                    display: none;
                }

                .close {
                    display: block;
                    font-size: 0;
                    width: 2.4rem;
                    height: 2.4rem;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9997 13.4179L16.5836 18.0027L17.9979 16.5886L13.4138 12.0035L17.9979 7.41847L16.5836 6.00439L11.9997 10.5892L7.41582 6.00439L6.00146 7.41847L10.5856 12.0035L6.00146 16.5886L7.41582 18.0027L11.9997 13.4179Z'/%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 2.4rem 2.4rem;
                }
            }
        }

        .slides {
            .item {
                &.active {
                    .tip.link {
                        display: flex;
                    }
                }
                .tip.link {
                    max-width: calc(100% - 2rem);
                    height: 5.6rem;
                    display: flex;
                    padding: 0 1.6rem;
                    white-space: nowrap;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-primary-blue;
                    border-radius: 1.2rem;
                    font-family: $font-primary;
                    font-size: 1.6rem;
                    font-weight: $font-weight-550;
                    color: $color-white;
                    text-transform: none;
                    bottom: 4.8rem;
                }
            }
        }
    }
}