.input-password {
    position: relative;
    display: flex;
    justify-content: center;
    // gap: 2.4rem;
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    margin-bottom: 1.2rem;

    &>* {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
    }

    &__number {
        position: relative;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 1.2rem;
        background-color: $color-gray-7;
        transition: 0.2s;

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 1.2rem;
            background-color: $color-primary-blue;
            opacity: 0;
            transition: 0.3s linear;
        }

        &.active {
            // background-color: $color-primary-blue;

            &:after {
                opacity: 1;
                animation: pulse 0.3s linear;
            }
        }

        .input-password.error & {
            // background-color: $color-red;

            &:after {
                opacity: 1;
                background-color: $color-red;
            }
        }
    }

    &__input {
        display: none;
    }

    &.error {
        animation: shaking 0.3s linear;
    }

    .field__error {
        position: absolute;
        top: calc(100% + 0.6rem);
    }
}

.number-panel {
    display: flex;
    flex-wrap: wrap;
    margin: 6.4rem auto -1.6rem;
    // gap: 1.6rem 3.2rem;
    max-width: 28rem;
    justify-content: center;
    align-items: center;

    &>* {
        margin-right: 3.2rem;
        margin-bottom: 1.6rem;

        &:nth-child(3n+3) {
            margin-right: 0;
        }}

    &__button,
    &__backspace,
    &__forgot-password {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7.2rem;
        height: 7.2rem;
        border-radius: 7.2rem;
        border: 0.1rem solid $color-gray-6;
        background-color: $color-white;
        font-size: 2.4rem;
        color: $color-dark;
        
        &_empty {
            opacity: 0;
        }


        svg {
            width: 2.4rem;
            height: 2.4rem;
        }
    }

    &__backspace {
        transition: 0.2s;

        &:disabled {
            opacity: 0;
        }
    }

    &__forgot-password {
        border: none;
        font-size: 1.3rem;
        line-height: 1.4rem;
        font-weight: $font-weight-500;
        color: $color-gray-5;
        text-align: center;
        cursor: pointer;

        &.active {
            color: $color-primary-blue;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes shaking {
    0% {
        transform: translateX(-1%);
    }

    20% {
        transform: translateX(2%);
    }

    40% {
        transform: translateX(-2%);
    }

    60% {
        transform: translateX(2%);
    }

    80% {
        transform: translateX(-1%);
    }

    100% {
        transform: translateX(0);
    }
}