.greeting {
  width: 100%;
  margin-top: 2.4rem;
  margin-bottom: 3.2rem;
  display: flex;
  align-items: center;

  &__text {
    width: calc(100% - 4.8rem);
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: $font-weight-650;
    color: $color-dark;
  }

  &__username {
    display: inline;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__avatar {
    position: relative;
    display: flex;
    width: 4rem;
    height: 4rem;
    flex: 0 0 4rem;
    margin-left: 0.8rem;
    border-radius: 4rem;
    overflow: hidden;

    img {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.user-actions {
  padding: 0.4rem 0;
}

.user-action {
  display: flex;
  align-items: center;
  padding: 1.7rem 0;
  cursor: pointer;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    flex: 0 0 2.4rem;
  }

  &__icon-angle {
    fill: $color-gray-12;
  }

  &__text {
    margin: 0 1.2rem;
    flex: 0 1 100%;
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: $font-weight-450;
    color: $color-dark;
  }

  &__details {
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: $font-weight-500;
    color: $color-gray-5;
  }
}
