.input-text {
    position: relative;
    width: 100%;
    height: 5.2rem;
    border-radius: 1.2rem;
    padding: 0 1.6rem;
    font-size: 1.6rem;
    background-color: $color-gray-1;
    transition: 0.2s;
    border: 0.2rem solid transparent;

    &.focused {
       border-color: $color-primary-blue;
       background-color: $color-white;
    }

    &.error {
        margin-bottom: 0.6rem;
        border-color: $color-red;
        background-color: $color-red-2;
    }

    &__placeholder {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.6rem;
        // line-height: 4.8rem;
        line-height: 2rem;
        font-weight: $font-weight-500;
        color: $color-gray-3;
        z-index: 1;
        transition: 0.2s;

        .input-text.focused &,
        .input-text.filled & {
            top: 0.7rem;
            transform: translateY(0);
            font-size: 1.2rem;
            line-height: 1.2rem;
            z-index: 5;
        }

        .input-text.error & {
            color: $color-red;
        }
    }

    &__input {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 2.2rem;
        line-height: 2rem;
        z-index: 2;
        transition: 0.2s;
        font-size: 1.6rem;

        &::placeholder {
            opacity: 0;
        }
       
        &:-webkit-autofill {
            -webkit-box-shadow: 0 10rem 0 0 inset $color-gray-1;
        }

        .input-text.focused & {
            &:-webkit-autofill {
                -webkit-box-shadow: 0 10rem 0 0 inset $color-white;
            }
        }
    }
}

.input-text {
    &__input[type="date"] {
        &::-webkit-datetime-edit {
            opacity: 0;

            .input-text.focused &,
            .input-text.filled & {
                opacity: 1;
            }
        }
    
    }
}