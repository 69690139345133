.offline-message {
  display: none;
  position: fixed;
  z-index: 9999;
  bottom: 90px;
  left: 10px;
  right: 10px;
  padding: 10px 20px;
  text-align: center;
  background-color: #e74c3c;
  color: #fff;
  font-size: 16px;
  border-radius: 10px;
  font-weight: 600;
  pointer-events: none;
  opacity: .9;

  .offline & {
    display: block;
  }
}