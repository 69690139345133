.questions {
	display: flex;
	flex-direction: column;
	margin-top: 1.6rem;
}

.question {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.6rem 0;
	border-bottom: 1px solid $color-gray-6;
	cursor: pointer;

	&:last-child {
		border-bottom: none;
	}

	&__text {
		font-size: 1.6rem;
		line-height: 2.2rem;
		font-weight: $font-weight-450;
		color: $color-dark;
	}

	svg {
		width: 2.4rem;
		height: 2.4rem;
		fill: $color-gray-12;
	}
}

.answers {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	// width: 100%;
	// height: 100%;
	transform: translateX(120vw);
	transition: 0.3s;
	background-color: $color-white;
	// padding-top: 6.4rem;
	z-index: 5;

	&.active {
		transform: translateX(0);

		.answer {
			display: block;
		}
	}

	&__back {
		position: absolute;
		width: 2.4rem;
		height: 2.4rem;
		top: 1.2rem;
		left: 1.2rem;
		cursor: pointer;
	}

	&-container {
		//padding-top: 4rem;
		padding-top: 1.6rem;
		background-color: #fff;
	}
}

.answer {
	display: none;
	height: 100%;
	padding-bottom: 11.6rem;

	&__title {
		margin-bottom: 1.6rem;
	}

	&__text {
		flex: 1 1 100%;
		font-size: 1.6rem;
		line-height: 2.2rem;
		font-weight: $font-weight-450;
		color: $color-dark-5;
	}

	&__link {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 3.2rem;
	}
}