.switch {
  &__view {
    position: relative;
    width: 4.8rem;
    height: 2.8rem;
    background-color: $color-gray-8;
    z-index: 1;
    transition: 0.2s;
    border-radius: 2.8rem;
    cursor: pointer;

    &:after {
      content: '';
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 2.4rem;
      position: absolute;
      top: 0.2rem;
      left: 0.2rem;
      background-color: $color-white;
      transition: 0.2s;
      cursor: pointer;
    }

    .switch__input:checked + & {
      background-color: $color-primary-blue;

      &:after {
        left: 2.2rem;
      }
    }
  }

  &__input {
    display: none;
  }
}