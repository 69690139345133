.modal-subscribe {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 32px;
    flex-grow: 1;
    height: 100%;
  }

  &__image {
    display: block;
    margin: 0 auto 16px;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -.02em;
    font-weight: 600;
  }

  &__text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -.01em;
    font-weight: 500;
    color: #42454B;
  }

  &__btn-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: auto;
  }

  &__btn {
    display: block;
    width: 100%;
  }
}