
.call {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7rem 0 8rem;
    text-align: center;

    &__info {
        display: flex;
        flex-direction: column;
    }

    &__status {
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: $font-weight-500;
        color: $color-white-op-50;
    }

    &__name {
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: $font-weight-650;
        color: $color-white;
    }

    &__actions {
        display: flex;
        height: 8rem;
        flex: 0 0 8rem;
        align-items: center;
        justify-content: center;
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 8rem;
        width: 8rem;
        height: 8rem;
        margin-right: 3.95rem;
        border-radius: 8rem;
        background-color: $color-white-op-12;
        transition: 0.2s;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            background-color: $color-white;
        }

        &_reset {
            background-color: $color-red;
        }

        svg {
            width: 4rem;
            height: 4rem;
            fill: $color-white;
            transition: 0.2s;
        }

        &.active {
            background-color: $color-white;

            svg {
                fill: $color-dark;
            }
        }
    }
}