.settings {
    padding-top: 6.4rem;

    &__avatar {
        width: 9.6rem;
        height: 9.6rem;
        margin: 2.4rem auto 1.6rem;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            border-radius: 9.6rem;
            object-fit: cover;
        }
    }

    &__add-photo {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.4rem solid $color-white;
        background-color: $color-gray-6;

        svg {
            width: 2.4rem;
            height: 2.4rem;
        }

        input[type="file"] {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            opacity: 0;
        }
    }
}

.setting {
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
    border-bottom: 1px solid $color-gray-6;
    cursor: pointer;

    &:last-child {
        border-bottom: none;
    }

    &__info {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        margin-right: 1.2rem;
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-weight: 400;
        color: $color-dark;
    }

    &__name {
        font-size: 1.2rem;
        line-height: 1.4rem;
        font-weight: 500;
        letter-spacing: 0.0065em;
        color: $color-gray-5;
    }

    &__value {
        margin-top: 0.1rem;
        font: inherit;
    }

    &__arrow {
        flex: 0 0 2.4rem;
        width: 2.4rem;
        height: 2.4rem;

        svg {
            fill: $color-gray-12;
        }
    }
}

.modal-setting {
    width: 100%;
    overflow-x: hidden;
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    &-step {
        position: relative;
        width: 100%;
        padding-top: 3.2rem;
        display: none;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: space-between;
        // transform: translateX(150%);
        transition: 0.5s;
        
        &.active {
            display: flex;
            // transform: translateX(0);
        }

        &.passed {
            display: none;
            // transform: translateX(-150%);
        }
    }

    &__title {
        max-width: 35rem;
        margin: 0 auto 3.2rem;
        display: flex;
        flex-direction: column;
        
        &_password {
            height: 5.6rem;
        }

        .text {
            margin-top: 1.2rem;
        }
    }
}

