.info-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.6rem;
    text-align: left;
    border-radius: 1.2rem;
    background-color: $color-gray-1;
    margin-bottom: 2.4rem;

    &__title {
        margin-bottom: 1.6rem;
        font-size: 1.6rem;
        font-weight: $font-weight-550;
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin: -0.7rem 0;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        color: $color-gray-2;
        
        svg {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: 0.8rem;
        }

        span {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }
    }
}