.input-code {
    display: flex;
    justify-content: center;
    // gap: 1.2rem;
    margin: 0 -0.6rem;

    &>* {
        margin: 0 0.6rem;
    }

    &.error {
        margin-bottom: 0.6rem;
    }

    &__number {
        width: 4.8rem;
        height: 5.2rem;
        border-radius: 1.2rem;
        border: 0.2rem solid transparent;
        padding: 0 1.2rem;
        font-size: 2.4rem;
        font-weight: $font-weight-650;
        background-color: $color-gray-1;
        text-align: center;

        .input-code.error & {
            border-color: $color-red;
            // background-color: $color-red-2;
        }

        &:focus {
            border-color: $color-primary-blue;
            background-color: $color-white;
        }
    }
}