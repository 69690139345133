.advantages {
	padding-bottom: 1.6rem;

	.advantage {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 1.6rem 0;
		border-top: 1px solid $color-gray-6;

		&:first-child {
			border-top: none;
		}

		&__icon {
			width: 2.4rem;
			height: 2.4rem;
			margin-right: 1.2rem;
		}

		&__info {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			width: 100%;
		}

		&__name {
			margin-bottom: 0.1rem;
			font-size: 1.6rem;
			line-height: 2.2rem;
			font-weight: $font-weight-450;
			color: $color-dark;
			width: 100%;
		}

		&__description {
			font-size: 1.3rem;
			line-height: 1.6rem;
			font-weight: $font-weight-500;
			color: $color-gray-5;
			width: 100%;
		}
	}
}