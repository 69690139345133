.h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: $font-weight-650;
    text-align: center;
    color: $color-dark;

    &_big {
        font-size: 2.8rem;
        line-height: 3.2rem;
    }
}

.h2 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: $font-weight-650;
    color: $color-dark;
}
