.alert {
	display: none;
	position: fixed;
	top: 15%;
	left: 50%;
	transform: translate(-50%, -100vh);
	transition: 0.3s;
	// display: flex;
	max-width: 26rem;
	width: fit-content;
	background-color: rgba($color-dark, 0.95);
	border-radius: 1.2rem;
	padding: 0.9rem 1.2rem 0.9rem 0.8rem;
	margin: 0 auto;

	&.active {
		display: flex;
		transform: translate(-50%, 0);
	}

	&__icon svg {
		fill: $color-white;
		width: 2.4rem;
		height: 2.4rem;
	}

	&__text {
		margin-left: 0.6rem;
		font-size: 1.6rem;
		line-height: 2.2rem;
		font-weight: $font-weight-550;
		color: $color-white;
	}
}