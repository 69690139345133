.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: none;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: rgba(#000, 0.4);
  //overflow-y: auto;

  &_visible {
    display: block;
  }

  &_fade {
    opacity: 1;
  }

  &_show {
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
    transition-duration: 0s;
    opacity: 1;
  }

  &__inner {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    padding: 1.6rem;
  }

  &__wrap {
    max-width: 54rem;
    width: 100%;
    padding: 2.8rem;
    position: relative;
    margin: auto;
    background-color: $color-white;
    border-radius: 1.6rem;
    text-align: center;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
      width: 4px; /* ширина scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* цвет дорожки */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0171DD; /* цвет плашки */
      border-radius: 20px; /* закругления плашки */
      border: 1px solid #0171DD; /* padding вокруг плашки */
    }

    &_d-f {
      display: flex;
      flex-direction: column;
    }

    .modal_w-close & {
      padding-top: 4.8rem;
    }

    .modal--no-bg > .modal__inner > & {
      background-color: transparent;

      .modal {
        z-index: 1002;
      }
    }
  }

  &__wrap {
    transform: translateY(100%);
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }

  &_fade &__wrap, &_show &__wrap {
    opacity: 1;
    transform: translateY(0);
    // -webkit-transform: translateY(0) scale(1);
    // -ms-transform: translateY(0) scale(1);
    // transform: translateY(0) scale(1);
  }

  &__title {
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: $font-weight-650;
    color: $color-dark;

    .modal--center-title & {
      text-align: center;
    }
  }

  &__text {
    margin-bottom: 3rem;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $color-dark-3;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    // gap: 0.8rem;
    margin: -0.4rem 0;

    & > * {
      margin: 0.4rem 0;
    }
  }

  &__close {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    width: 2.4rem;
    height: 2.4rem;
    cursor: pointer;

    svg {
      fill: $color-gray-3;
    }
  }

  &__picture {
    width: 12.8rem;
    height: 8.8rem;
    margin: 0 auto 2.4rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    &_chats {
      background-image: url('../../../../public/assets/images/chats-modal.png');
    }

    &_calls {
      background-image: url('../../../../public/assets/images/calls-modal.png');
    }
  }

  &__name {
    padding: 1.6rem 0;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 600;
    color: $color-dark;
  }

  &__title-sticky {
    width: max-content;
    position: sticky;
    top: 1rem;
    z-index: 6;

    .modal__header--faq & {
      position: static;
    }
  }

  &__qr {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  &__qr-empty {
    font-size: 24px;
    font-weight: 500;
  }
}

.modal_type-2 {
  .modal {
    &__inner {
      padding: 0;
      width: 100%;
      overflow: hidden;
    }

    &__wrap {
      position: relative;
      width: 100%;
      min-height: 100%;
      border-radius: 0;
      padding: 0 1.6rem 0;
      text-align: left;
    }

    &__header {
      width: 100%;
      min-height: 4.8rem;
      background-color: $color-white;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 5;

      &--faq {
        display: flex;
        align-items: center;
      }
    }

    &__close {
      z-index: 5;
    }
  }

  .auth-step {
    padding-top: 3.2rem;
  }

  .modal-reviews {
    visibility: hidden;
    opacity: 0;
    height: 0;
  }
}

.modal_type-3 {
  .modal {
    &__inner {
      padding: 0;
      justify-content: flex-end;
    }

    &__wrap {
      margin-bottom: 0;
      padding: 2rem 1.6rem;
      border-radius: 1.6rem 1.6rem 0 0;
      text-align: left;
    }

    &__close {
      top: 2.2rem;
    }
  }

  &.modal-settings {
    .modal {
      &__wrap {
        padding-bottom: 6.4rem;
      }
    }
  }
}

.modal-chat-message {
  .modal {
    &__wrap {
      padding-top: 0;
      padding-bottom: 3.2rem;
      max-height: 120px;
      overflow: hidden;
    }

    &__close {
      top: 1.2rem;
    }
  }
}

.modal-call-request {
  .modal__wrap {
    max-height: 400px;
    overflow: hidden;
  }
}

.modal-logout {
  .modal__wrap {
    max-height: 250px;
    overflow: hidden;
  }
}

.modal-settings-name {
  .modal__wrap {
    max-height: 380px;
    overflow: hidden;
  }
}

.modal-settings-birthday, .modal-settings-city, .modal-settings-email {
  .modal__wrap {
    max-height: 250px;
    overflow: hidden;
  }
}

.modal-forgot-password, .modal-chats-access {
  .modal__wrap {
    max-height: 320px;
    overflow: hidden;
    transform: none;
  }
}

.modal-prepare {
  .modal__wrap {
    max-height: 400px;
    overflow: hidden;
    transform: none;
  }

  .modal__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__text {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -.011em;
  }

  &__bottom-box {
    margin-top: auto;
  }

  &__checkbox {
    margin-bottom: 24px;
  }
}

.modal-city {
  .modal__wrap {
    height: unset;
    overflow: hidden;
    transform: none;
  }

  .modal__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal__close {
    top: 1.2rem;
  }

  &__title {
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: -.017em;
  }

  &__bottom-box {
    margin-top: auto;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 32px;
  }
}

.modal-settings-qr {
  .modal__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-grow: 1;
  }
}

.modal-steps {
  .modal__wrap {
    display: flex;
    flex-direction: column;
    height: unset;
    min-height: 70%;
    overflow: hidden;
    transform: none;
  }

  .modal__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -.011em;
  }

  &__elem {
    display: flex;
    gap: 8px;
  }

  &__elem-icon-box {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background-color: #F0F8FF;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1;
    font-weight: 700;
    color: #0171DD;
  }

  &__elem-icon {
    width: 16px;
    height: 16px;
  }

  &__elem-title {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: -.01em;
  }

  &__elem-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: -.006em;
    color: #676F7B;
  }

  &__bottom-box {
    margin-top: auto;
  }
}

.modal--show {
  overscroll-behavior: contain;
  .modal__wrap {
    transform: unset;
    transition: transform .15s ease-in-out;
  }
}

.modal-step-detail {
  .modal__close {
    top: 1.1rem;
  }
  &__data-group {
    padding: 16px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #EEF1F5;
    }
  }

  &__data-label {
    margin-bottom: 1px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: #7F8895;
  }

  &__data-value {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
  }

  &__panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    letter-spacing: -.014em;
  }
}

.modal-setup {
  .modal__wrapper {
    position: relative;
  }
  .modal__content {
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
    overflow-y: auto;
  }
  .modal__header {
    background: #fff;
    height: 4.8rem;
    border-radius: 1.6rem 1.6rem 0 0;
  }
  .modal__wrap {
    overflow: hidden;
    max-height: 65%;
    padding-bottom: 0 !important;
  }

  &--installable {
    .modal__wrap {
      height: auto;
    }
  }

  .modal__close {
    top: 1.2rem;
  }

  &__btn-text {
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  &__setup-btn {
    margin-bottom: 32px;
  }
  &__title {
    margin-bottom: 80px;
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    letter-spacing: -.02em;
    text-align: center;
  }
  &__grid {
    display: flex;
    flex-direction: column;
    gap: 48px
  }
  &__grid-el {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: -.011em;

    a {
      color: #0171DD;
      text-decoration: underline;
    }
  }
  &__grid-img {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }
}