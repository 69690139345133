.home {
	padding: 80px 20px;

	&__text {
		margin: 0 auto;
		max-width: 500px;
		font-size: 20px;
		line-height: 26px;
	}
}
