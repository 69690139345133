.progressbar {
    width: 100%;
    height: 0.3rem;
    background-color: $color-gray-1;

    &__progress {
        width: 0;
        height: 100%;
        background-color: $color-primary-blue;
        transition: 0.2s;
    }
}