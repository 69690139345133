.loading {
    display: none!important;
}

.sk {
    &-stories {
        overflow: hidden;
        display: flex;
        gap: 0.4rem;
        margin: 0 -2rem;
        padding: 0 2rem;
        padding-bottom: 1.6rem;
    }

    &-story {
        flex: 1 0 12rem;
        width: 12rem;
        height: 14rem;
        border-radius: 1.2rem;
        overflow: hidden;
    }

    &-reviews {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    &-title {
        height: 2.8rem;
        margin-top: 2.4rem;
    }

    &-text {
        width: 100%;
        // height: 2.2rem;
    }

    &-review {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        padding: 3.2rem 0;
        border-bottom: 1px solid $color-gray-7;

        &:last-child {
            border-bottom: none;
        }

        &__title {
            display: flex;
            gap: 0.8rem;
            align-items: center;
        }

        &__user-photo {
            width: 4rem;
            height: 4rem;
            flex: 1 0 4rem;
        }

        &__info {
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
            flex: 1 1 100%;
        }

        &__content {
            height: 11rem;
        }
    }
}